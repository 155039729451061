import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import StickyTitle from "../components/sticky-title";
import ExhibitionPreview from "../components/exhibition-preview";
import { ScrollProvider } from "../context/ScrollContext";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";

import Layout from "../containers/layout";

export const query = graphql`
  query ExhibitionsPageQuery($currentDate: Date!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    currentExhibitions: allSanityExhibition(
      sort: { fields: [startedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null }, startedAt: { lte: $currentDate }, endedAt: { gte: $currentDate }}
    ) {
      edges {
        node {
          id
          _key
          endedAt
          startedAt
          title
          slug {
            current
          }
          featuredMedia {
            largeImage {
              alt
              asset {
                _id
              }
              crop {
                _key
                _type
                bottom
                left
                right
                top
              }
              hotspot {
                _key
                _type
                height
                width
                x
                y
              }
            }
          }
        }
      }
    }
    upcomingExhibitions: allSanityExhibition(
      sort: { fields: [startedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null }, startedAt: { gt: $currentDate }}
    ) {
      edges {
        node {
          id
          _key
          endedAt
          startedAt
          title
          slug {
            current
          }
          featuredMedia {
            _key
            largeImage {
              _key
              asset {
                _id
              }
              alt
              crop {
                _key
                _type
                bottom
                left
                right
                top
              }
              hotspot {
                _key
                _type
                height
                width
                x
                y
              }
            }
          }
        }
      }
    }
    pastExhibitions: allSanityExhibition(
      sort: { fields: [startedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null }, endedAt: { lt: $currentDate }}
    ) {
      edges {
        node {
          id
          _key
          endedAt
          startedAt
          title
          slug {
            current
          }
          featuredMedia {
            _key
            largeImage {
              _key
              asset {
                _id
              }
              crop {
                _key
                _type
                bottom
                left
                right
                top
              }
              hotspot {
                _key
                _type
                height
                width
                x
                y
              }
            }
          }
        }
      }
    }
  }
`;

const ExhibitionsPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const currentExhibitionNodes = (data || {}).currentExhibitions
    ? mapEdgesToNodes(data.currentExhibitions)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : null;
  const upcomingExhibitionNodes = (data || {}).upcomingExhibitions
    ? mapEdgesToNodes(data.upcomingExhibitions)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : null;
  const pastExhibitionNodes = (data || {}).pastExhibitions
    ? mapEdgesToNodes(data.pastExhibitions)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : null;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <ScrollProvider>
        <SEO title={site.title} description={site.description} keywords={site.keywords} />
        {currentExhibitionNodes.length ? (
          <div>
            <StickyTitle title={`Current`} />
            {currentExhibitionNodes.map(item => <ExhibitionPreview key={item._key} {...item} />)}
          </div>
        ) : ''}
        {upcomingExhibitionNodes.length ? (
          <div>
            <StickyTitle title={`Upcoming`} />
            {upcomingExhibitionNodes.map(item => <ExhibitionPreview key={item._key} {...item} />)}
          </div>
        ) : ''}
        {pastExhibitionNodes.length ? (
          <div>
            <StickyTitle title={`Past`} />
            {pastExhibitionNodes.map(item => <ExhibitionPreview key={item._key} {...item} />)}
          </div>
        ) : ''}
      </ScrollProvider>
    </Layout>
  );
};

export default ExhibitionsPage;
