import React from "react";
import { Link } from "gatsby";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";
import clientConfig from "../../client-config";
import { format } from "date-fns";

import * as styles from "./exhibition-preview.module.css";

const renderFeaturedImage = item => {
  let img = null;

  if (item && item.largeImage && item.largeImage.asset) {
    const largeImageData = getGatsbyImageData(
      item.largeImage,
      { maxWidth: 1440 },
      clientConfig.sanity
    );
    
    img = (
      <GatsbyImage
        image={largeImageData}
        alt={item.largeImage.alt}
      />
    );
  }
  return img;
};

function ExhibitionPreview(props) {
  const featuredImage = renderFeaturedImage(props.featuredMedia);
  return (
    <div className={styles.root}>
      <Link to={`/exhibitions/${props.slug.current}`}>
        <div className={styles.thumb}>
          {featuredImage}
        </div>
        <div className={styles.text}>
          <h3 className={styles.title}>{props.title}</h3>
          <p>{format(new Date(props.startedAt), "D MMMM YYYY")} – {format(new Date(props.endedAt), "D MMMM YYYY")}</p>
        </div>
      </Link>
    </div>
  );
}

export default ExhibitionPreview;